import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`V3 and V4: Font-family changed to Roboto, Changed login screen, Main colors, Backgrounds, Icons, Button colors and added Loading animations.`}</li>
      <li parentName="ul">{`V3: Changed buttons format, dashboard card design and Coremedia Visual Alignment.`}</li>
      <li parentName="ul">{`Changed App Icons and Logos and Dialogue Colors.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      